import React, { MouseEvent } from 'react';

interface Props {
  className?: string;
  onClick?: (event: MouseEvent<Element>) => void;
}

const ExpandIcon3 = ({ className, onClick }: Props) => {

  return (
    <svg width="30" height="30" viewBox="0 0 20 13" fill="none" xmlns="http://www.w3.org/2000/svg" className={className} onClick={onClick}>
      <g filter="url(#filter0_d_83_7)">
        <path d="M4 12V6.85714H5.71429V10.2857H9.14286V12H4ZM14.2857 5.14286V1.71429H10.8571V0H16V5.14286H14.2857Z" />
      </g>
    </svg>
  );
};

export default ExpandIcon3;
