import React, { Fragment, useEffect, useState } from 'react';
import LoadingDataSimple from 'components/loading-data/simple';
import ListingCard, { ListingCardData } from 'components/listing-card';
import styles from './style.module.scss';
import ConditionalWrapper from 'components/conditional-wrapper';
import { trackEvent } from 'utils/google-tag-manager';
import { GTM_CLICK_LISTING_CARD } from 'constants/events';
import { useRouter } from 'next/router';
import SaveSearchCard from 'components/listing-card/save-search-card';
import { useFeaturesContext } from 'contexts';
import LoadWhenVisible from 'components/load-when-visible';
import LoadingSkeleton from 'components/loading-skeleton';
import dynamic from 'next/dynamic';
import MapButtonCard from 'components/listing-card/map-card';
import { useExperiment } from '@growthbook/growthbook-react';

import type { Breadcrumb } from 'components/breadcrumbs';
import type { ListingParams } from 'contexts/preferences/listing-params/types';

interface Props {
  listings: readonly ListingCardData[];
  isLoading: boolean;
  testId?: string;
  params: {
    page: {
      number: number;
      size: number;
    };
  };
  meta: {
    totalCount: number;
    totalPages: number;
  };
  breadcrumbs?: Breadcrumb[];
  setPageNumber: (pageNumber: number) => void;
  listingParams?: ListingParams;
  trackMapCardExperimentView?: () => void;
  trackMapCardClick?: () => void;
  showMapCardExperimentView?: boolean;
}

export default function ListingsGrid({ listings, testId, isLoading, params, meta, breadcrumbs, setPageNumber, listingParams, trackMapCardExperimentView, trackMapCardClick, showMapCardExperimentView }: Props) {
  const { isMobile } = useFeaturesContext();
  const router = useRouter();
  const isOnAreaPage = router.asPath.includes('-real-estate');
  const imagePreloadAmount = isMobile ? 2 : 9;
  const [isLazyListingsVisible, setIsLazyListingsVisible] = useState(false);
  const [isPaginationNavVisible, setIsPaginationNavVisible] = useState(false);
  const [PaginationNav, setPaginationNav] = useState<any>();
  const [isBreadcrumbsVisible, setIsBreadcrumbsVisible] = useState(false);
  const [ListingsGridBreadcrumbs, setListingsGridBreadcrumbs] = useState<any>();

  useEffect(() => {
    if (isPaginationNavVisible && !PaginationNav) {
      setPaginationNav(dynamic(import('components/control/pagination-nav')));
    }

    if (isBreadcrumbsVisible && !ListingsGridBreadcrumbs) {
      setListingsGridBreadcrumbs(dynamic(import('components/listings-grid/breadcrumbs')));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPaginationNavVisible, isBreadcrumbsVisible]);

  useEffect(() => {
    if (trackMapCardExperimentView) trackMapCardExperimentView();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <LoadingDataSimple isLoading={isLoading} data={listings}>
      {listings =>
        <>
          <div className={styles.grid} data-testid={testId}>
            {listings.map((listing, index) =>{
              const isPreloaded = index < imagePreloadAmount;
              if (index === 8 && isOnAreaPage && listingParams) {
                // adding save search advert
                return (
                  <Fragment key={index}>
                    <SaveSearchCard listingParams={listingParams}/>
                    <ConditionalWrapper key={index} condition={isOnAreaPage} wrapper={children => <div onClick={() => trackEvent(GTM_CLICK_LISTING_CARD)}>{children}</div>}>
                      <LoadWhenVisible onValueChange={() => setIsLazyListingsVisible(true)}>
                        {isLazyListingsVisible ? <ListingCard listing={listing} key={index} isImageVisible={false} /> : <LoadingSkeleton />}
                      </LoadWhenVisible>
                    </ConditionalWrapper>
                  </Fragment>
                );
              }
              if (index === 3 && isOnAreaPage && showMapCardExperimentView) {
                return (
                  <Fragment key={index}>
                    <MapButtonCard trackMapCardClick={trackMapCardClick}/>
                    <ConditionalWrapper key={index} condition={isOnAreaPage} wrapper={children => <div onClick={() => trackEvent(GTM_CLICK_LISTING_CARD)}>{children}</div>}>
                      <LoadWhenVisible onValueChange={() => setIsLazyListingsVisible(true)}>
                        {isLazyListingsVisible ? <ListingCard listing={listing} key={index} isImageVisible={false} /> : <LoadingSkeleton />}
                      </LoadWhenVisible>
                    </ConditionalWrapper>
                  </Fragment>
                );
              }
              return (
                <ConditionalWrapper key={index} condition={isOnAreaPage} wrapper={children => <div onClick={() => trackEvent(GTM_CLICK_LISTING_CARD)}>{children}</div>}>
                  <>
                    {isPreloaded && <ListingCard listing={listing} key={index} isImageVisible={index === 0} />}
                    {!isPreloaded && (
                      <LoadWhenVisible onValueChange={() => setIsLazyListingsVisible(true)}>
                        {isLazyListingsVisible ? <ListingCard listing={listing} key={index} isImageVisible={false} /> : <LoadingSkeleton />}
                      </LoadWhenVisible>
                    )}
                  </>
                </ConditionalWrapper>
              );
            })}
          </div>
          <LoadWhenVisible onValueChange={() => setIsBreadcrumbsVisible(true)}>
            {isBreadcrumbsVisible && ListingsGridBreadcrumbs && <ListingsGridBreadcrumbs breadcrumbs={breadcrumbs} />}
            {!isBreadcrumbsVisible && <LoadingSkeleton />}
          </LoadWhenVisible>
          <LoadWhenVisible onValueChange={() => setIsPaginationNavVisible(true)}>
            {isPaginationNavVisible && PaginationNav && <PaginationNav
              pageNumber={params.page.number}
              totalPages={meta.totalPages}
              setPageNumber={setPageNumber}
            />}
            {!isPaginationNavVisible && <LoadingSkeleton />}
          </LoadWhenVisible>
        </>
      }
    </LoadingDataSimple>
  );
}
